/* website: 4419-hwy11ram
 * created at 2025-01-27 13:50 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/fca.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.groups__4571{
    .vdp-used-banner--details{
        .showroom-vdp-used-car-info{
          .car-name{
            font-size: 30px !important;
            line-height: 35px !important;
          }
        }
    }
  }
}